import Link from "next/link";
import React from "react";

const openMail = () => {
  window.open("mailto:info@chatq.ru");
};
const Copyright = () => {
  return (
    <>
      <div className="copyright-area copyright-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="copyright-left">
                <ul className="ft-menu link-hover">
                  <li>
                    <Link href="/privacy-policy">Политика конфиденциальности</Link>
                  </li>
                  <li>
                    <Link href="/terms-policy">Пользовательское соглашение</Link>
                  </li>
                  {/* <li>
                    <Link href="/contact">Связатся С Нами</Link>
                  </li> */}
                  <li>
                    <a onClick={openMail} style={{ cursor: "pointer" }}>Связатся С Нами</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12">
              <div className="copyright-right text-center text-lg-end">
                <p className="copyright-text">
                  Все права защищены  © 2024
                  <Link
                    href="https://chatq.ru"
                    className="btn-read-more ps-2"
                  >
                    <span>ChatQ</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Copyright;
